import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Box,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  useTheme,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import ReactEcharts from "echarts-for-react";
import { t } from "i18next";
import moment, { Moment } from "moment";
import "moment/locale/pt-br";
import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-dates";
import { END_DATE, START_DATE } from "react-dates/constants";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useSelector } from "react-redux";
import HistoricDetailsGraph from "../../GraphOptions/historicDetailsGraph";
import LocalDataService from "../../Services/LocalDataService";
import { getDb } from "../../store/slices/dbSlice";
import newDi2winDataService from "../../Services/NewDi2winDataService";
import { hideLoading } from "../../Utils/hideLoading";
import { showLoading } from "../../Utils/showLoading";
import { syncChartsTooltip, syncChartsZoom } from "../../Utils/syncGraphs";
import {
  getDigitalTwinId,
  getIsDigitalTwinDefined,
} from "../../store/slices/digitalTwinSlice";
import { getDigitalTwinsList } from "../../store/slices/digitalTwinsListSlice";
import "./HistoricDetailsView.css";
import { getIsDarkTheme } from "../../store/slices/themeSlice";

import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "../RecommendationHistoryView/DateRangePickerStyles.css";
import downloadCsvHelper from "../../Utils/downloadCsvHelper";
import { Fullscreen } from "@mui/icons-material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Sensor {
  id: number;
  name: string;
  unit: {
    abbreviation: string;
  };
  status?: boolean;
}

let zoomControl = false;

const HistoricDetailsView = () => {
  const digitalTwinId = useSelector(getDigitalTwinId);
  const isDigitalTwinDefined = useSelector(getIsDigitalTwinDefined);
  const digitalTwins = useSelector(getDigitalTwinsList);

  const [days, setDays] = useState(1);
  const [selectedDigitalTwinId, setSelectedDigitalTwinId] = useState<number>(
    isDigitalTwinDefined ? digitalTwinId : digitalTwins[0].id
  );

  const [dataToDownload, setDataToDownload] = useState<any>([]);
  const [sensors, setSensors] = useState<any>(null);
  const [selectedSensors, setSelectedSensors] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<Moment>(moment());
  const [endDate, setEndDate] = useState<Moment>(moment());
  const [focusedInput, setFocusedInput] = useState();
  const chart1Ref = useRef(null as any);
  const chart2Ref = useRef(null as any);
  const chart3Ref = useRef(null as any);
  const [showChart, setShowChart] = useState([false, false, false]);
  const arrayOfCharts = [chart1Ref, chart2Ref, chart3Ref];
  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);
  const isDarkTheme = useSelector(getIsDarkTheme);
  const [dataList, setDataList] = useState<any[]>([]);
  const db = useSelector(getDb);

  useEffect(() => {
    const getSensors = () => {
      LocalDataService.get_digitalTwin(selectedDigitalTwinId)
        .then((data) => {
          if (data.data[0]) {
            // console.log(data.data[0].sensors);
            setSensors(data.data[0].sensors);
          }
        })
        .catch((error) => {
          console.error("Error fetching sensors:", error);
        });
    };

    getSensors();
  }, [selectedDigitalTwinId]);

  useEffect(() => {
    if (dataList.length !== 0) {
      handleDownloadCSV();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataList]);

  const getAxisLabelFormatter = (element) => {
    if (element.type.name === "booleano") {
      const labels = element.unit.abbreviation.split("/");
      return function (value) {
        if (value === 1) return labels[0];
        if (value === 0) return labels[1];
        else return "";
      };
    }
    return "{value}";
  };

  const reloadDataOnFormSubmit = async () => {
    setDataToDownload([]);
    const dataListAux: any[] = [];

    const days = Math.abs(startDate.diff(endDate, "days"));

    // console.log(days);

    setDays(days + 1);
    if (days === 0) {
      setShowChart([true, false, false]);
    } else if (days === 1) {
      setShowChart([true, true, false]);
    } else if (days === 2) {
      setShowChart([true, true, true]);
    }

    if (selectedSensors.length > 0) {
      await chart1Ref.current?.getEchartsInstance().setOption({}, true);
      await chart2Ref.current?.getEchartsInstance().setOption({}, true);
      await chart3Ref.current?.getEchartsInstance().setOption({}, true);
      showLoading([chart1Ref, chart2Ref, chart3Ref]);
      const showGraphCopy = showChart.slice();
      if (selectedSensors.length > 0) {
        for (let i = 0; i < days + 1; i++) {
          let graphDatas: Array<any> = [];

          const aux = startDate
            .clone()
            .add(i, "days")
            .set({ hour: 0, minute: 0, second: 0 });
          const start = aux.clone();
          const end = aux.clone().endOf("day");

          for (const activeSensor of selectedSensors) {
            const isBooleanSensor = activeSensor.type.name === "booleano";
            const activeSensorChartData = await newDi2winDataService.getRawInfo(
              db.dbName,
              db.collection,
              parseInt(activeSensor.number),
              activeSensor.device_id,
              start,
              end
            );

            dataListAux.push(activeSensorChartData);

            const formattedChartData = activeSensorChartData.map(
              (chartDataElement) => {
                if (isBooleanSensor) {
                  const booleanSensorValues = activeSensor.unit.name.split("/");
                  return [
                    moment(chartDataElement.timestamp).toISOString(),
                    chartDataElement.value === false ? 0 : 1,
                    chartDataElement.value === false
                      ? booleanSensorValues[1]
                      : booleanSensorValues[0],
                  ];
                } else {
                  return [
                    moment(chartDataElement.timestamp).toISOString(),
                    chartDataElement.value,
                  ];
                }
              }
            );
            graphDatas.push({
              series: {
                name: activeSensor.name,
                type: "line",
                data: formattedChartData,
                encode: isBooleanSensor && {
                  x: 0,
                  y: 1,
                  tooltip: 2,
                },
                step: isBooleanSensor,
              },
              yAxis: {
                name: activeSensor.unit.abbreviation,
                type: "value",
                alignTicks: true,
                scale: true,
                axisLabel: {
                  formatter: getAxisLabelFormatter(activeSensor),
                },
                axisTick: {
                  show: true,
                  alignWithLabel: true,
                },
                axisLine: {
                  show: true,
                  lineStyle: {},
                },
              },
            });
          }
          setDataList(dataListAux);

          arrayOfCharts[i].current
            ?.getEchartsInstance()
            .setOption(
              HistoricDetailsGraph(
                graphDatas,
                start.subtract(0, "hours").toISOString(),
                end.subtract(0, "hours").toISOString(),
                new Date(
                  startDate.clone().add(i, "days").toISOString()
                ).toLocaleDateString("pt-br"),
                isDarkTheme
              )
            );

          hideLoading([arrayOfCharts[i]]);
        }
        for (let j = 0; j > days + 1; j++) {
          showGraphCopy[j] = true;
        }
        chart1Ref.current
          ?.getEchartsInstance()
          .on("datazoom", function (eventDetails: any) {
            let start: any;
            let end: any;
            if (eventDetails.batch !== undefined) {
              start = eventDetails.batch[0].start;
              end = eventDetails.batch[0].end;
              chart2Ref.current?.getEchartsInstance().dispatchAction({
                type: "dataZoom",
                start: start,
                end: end,
              });
              chart3Ref.current?.getEchartsInstance().dispatchAction({
                type: "dataZoom",
                start: start,
                end: end,
              });
            } else {
              start = eventDetails.start;
              end = eventDetails.end;
            }
          });
        chart2Ref.current
          ?.getEchartsInstance()
          .on("datazoom", function (eventDetails: any) {
            let start: any;
            let end: any;
            if (eventDetails.batch !== undefined) {
              start = eventDetails.batch[0].start;
              end = eventDetails.batch[0].end;
              chart1Ref.current?.getEchartsInstance().dispatchAction({
                type: "dataZoom",
                start: start,
                end: end,
              });
              chart3Ref.current?.getEchartsInstance().dispatchAction({
                type: "dataZoom",
                start: start,
                end: end,
              });
            } else {
              start = eventDetails.start;
              end = eventDetails.end;
            }
          });
        chart3Ref.current
          ?.getEchartsInstance()
          .on("datazoom", function (eventDetails: any) {
            let start: any;
            let end: any;
            if (eventDetails.batch !== undefined) {
              start = eventDetails.batch[0].start;
              end = eventDetails.batch[0].end;
              chart1Ref.current?.getEchartsInstance().dispatchAction({
                type: "dataZoom",
                start: start,
                end: end,
              });
              chart2Ref.current?.getEchartsInstance().dispatchAction({
                type: "dataZoom",
                start: start,
                end: end,
              });
            } else {
              start = eventDetails.start;
              end = eventDetails.end;
            }
          });
      }
      chart1Ref.current
        ?.getEchartsInstance()
        .on("datazoom", function (eventDetails: any) {
          let start: any;
          let end: any;

          if (eventDetails.batch !== undefined) {
            start = eventDetails.batch[0].start;
            end = eventDetails.batch[0].end;
            chart2Ref.current?.getEchartsInstance().dispatchAction({
              type: "dataZoom",
              start: start,
              end: end,
            });
            chart3Ref.current?.getEchartsInstance().dispatchAction({
              type: "dataZoom",
              start: start,
              end: end,
            });
          } else {
            start = eventDetails.start;
            end = eventDetails.end;
          }
          if (zoomControl === false) {
            if (end - start < 5) {
              zoomControl = true;
              chart1Ref.current?.getEchartsInstance().setOption({
                series: [
                  { showSymbol: true, smooth: false },
                  { showSymbol: true, smooth: false },
                  { showSymbol: true, smooth: false },
                ],
              });
              chart2Ref.current?.getEchartsInstance().setOption({
                series: [
                  { showSymbol: true, smooth: false },
                  { showSymbol: true, smooth: false },
                  { showSymbol: true, smooth: false },
                ],
              });
              chart3Ref.current?.getEchartsInstance().setOption({
                series: [
                  { showSymbol: true, smooth: false },
                  { showSymbol: true, smooth: false },
                  { showSymbol: true, smooth: false },
                ],
              });
            }
          } else {
            if (end - start >= 5) {
              zoomControl = false;
              chart1Ref.current?.getEchartsInstance().setOption({
                series: [
                  { showSymbol: false },
                  { showSymbol: false },
                  { showSymbol: false },
                ],
              });
              chart2Ref.current?.getEchartsInstance().setOption({
                series: [
                  { showSymbol: false },
                  { showSymbol: false },
                  { showSymbol: false },
                ],
              });
              chart3Ref.current?.getEchartsInstance().setOption({
                series: [
                  { showSymbol: false },
                  { showSymbol: false },
                  { showSymbol: false },
                ],
              });
            }
          }
        });
      chart2Ref.current
        ?.getEchartsInstance()
        .on("datazoom", function (eventDetails: any) {
          let start: any;
          let end: any;
          if (eventDetails.batch !== undefined) {
            start = eventDetails.batch[0].start;
            end = eventDetails.batch[0].end;
            chart1Ref.current?.getEchartsInstance().dispatchAction({
              type: "dataZoom",
              start: start,
              end: end,
            });
            chart3Ref.current?.getEchartsInstance().dispatchAction({
              type: "dataZoom",
              start: start,
              end: end,
            });
          } else {
            start = eventDetails.start;
            end = eventDetails.end;
          }
        });
      chart3Ref.current
        ?.getEchartsInstance()
        .on("datazoom", function (eventDetails: any) {
          let start: any;
          let end: any;
          if (eventDetails.batch !== undefined) {
            start = eventDetails.batch[0].start;
            end = eventDetails.batch[0].end;
            chart1Ref.current?.getEchartsInstance().dispatchAction({
              type: "dataZoom",
              start: start,
              end: end,
            });
            chart2Ref.current?.getEchartsInstance().dispatchAction({
              type: "dataZoom",
              start: start,
              end: end,
            });
          } else {
            start = eventDetails.start;
            end = eventDetails.end;
          }
        });
    }
  };

  const handleDownloadCSV = () => {
    const localStartDate = startDate.clone().startOf("day");
    const localEndDate = endDate.clone().endOf("day");
    // Lógica para consultar os dados dos sensores selecionados no intervalo de tempo selecionado
    const dataToFetch = selectedSensors.map((sensor) => {
      return {
        name: sensor.name,
        device: sensor.device_id,
        id: sensor.id,
        number: sensor.number,
        startDate: localStartDate,
        endDate: localEndDate,
        unit: sensor.unit.abbreviation,
      };
    });

    dataToFetch.forEach((sensor, index) => {
      setDataToDownload((dataToDownload) => [
        ...dataToDownload,
        {
          name: sensor.name,
          sensor: sensor.id,
          startDate: sensor.startDate,
          endDate: sensor.endDate,
          unit: sensor.unit,
          rawData: dataList[index],
        },
      ]);
    });
  };

  useEffect(() => {
    syncChartsZoom([chart1Ref, chart2Ref, chart3Ref], "datazoom");
    return () => {};
  }, [showChart]);

  useEffect(() => {
    syncChartsTooltip([chart1Ref, chart2Ref, chart3Ref], "mousemove");
    return () => {};
  }, [showChart]);

  const isOutsideRange = (day) => {
    if (focusedInput === END_DATE) {
      const x = day.isAfter(startDate.clone().add(2, "days"));
      const y = day.isBefore(startDate.clone().subtract(3, "days"));
      return x || y || day.isAfter(moment().add(0, "days"));
    }
    return day.isAfter(moment().add(0, "days"));
  };

  const checkMultipleUnitsSelected = (sensors) => {
    const selectedUnits = new Set();
    for (let i = 0; i < sensors.length; i++) {
      const sensor = sensors[i];
      selectedUnits.add(sensor.unit.name);
    }
  };

  useEffect(() => {
    const activeSensors =
      sensors && sensors.length > 0 && sensors.filter((e) => e.status === true);
    activeSensors && checkMultipleUnitsSelected(activeSensors);
    return () => {};
  }, [sensors]);

  const compareNames = (a: any, b: any) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  };

  const handleToggleSensor = (sensor: Sensor) => {
    const sensorUnit = sensor.unit.abbreviation;

    if (selectedUnits.includes(sensorUnit) || selectedUnits.length < 2) {
      setSelectedSensors((prevSensors) =>
        prevSensors.map((prevSensor) =>
          prevSensor.id === sensor.id
            ? {
                ...prevSensor,
                status: !prevSensor.status,
              }
            : prevSensor
        )
      );
      const units = (prevUnits) =>
        prevUnits.includes(sensorUnit)
          ? prevUnits.filter((unit) => unit !== sensorUnit)
          : [...prevUnits, sensorUnit];

      setSelectedUnits(units);
      // console.log(selectedUnits);
    } else {
      alert("Selecione apenas dois sensores");
    }
  };

  const handleSensorSelection = () => {
    const updatedSensors = sensors.map((sensor) => ({
      ...sensor,
      status: true, // Define como verdadeiro, pois o sensor foi selecionado
    }));
    setSelectedSensors(updatedSensors); // Atualiza o estado dos sensores selecionados
    setSelectedUnits(updatedSensors.map((sensor) => sensor.unit.abbreviation));
  };

  const theme = useTheme();

  const [isFullscreen, setIsFullscreen] = useState(false);

  const chartContainerRef1 = useRef<HTMLDivElement>(null);
  const chartContainerRef2 = useRef<HTMLDivElement>(null);
  const chartContainerRef3 = useRef<HTMLDivElement>(null);

  // };
  const toggleFullscreen = (chartContainerRef) => {
    if (chartContainerRef.current) {
      if (!isFullscreen) {
        chartContainerRef.current &&
          chartContainerRef.current.requestFullscreen().catch((err) => {
            console.error(
              `Failed to enable fullscreen mode: ${err.message} (${err.name})`
            );
          });
      } else {
        document.exitFullscreen().catch((err) => {
          console.error(
            `Failed to exit fullscreen mode: ${err.message} (${err.name})`
          );
        });
      }
      setIsFullscreen(!isFullscreen);
    }
  };

  return (
    <Box
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 4fr",

        height: "calc(100vh - 150px)",
        width: "calc(100vw - 90px)",
        gap: "10px",
        margin: "10px",
      }}
    >
      <Paper
        elevation={0}
        id="formulario"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",

          padding: "10px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <InputLabel>{`${t("historic_data:filterPanelPeriod")}`} :</InputLabel>
          <DateRangePicker
            minimumNights={0}
            startDate={startDate}
            startDateId={START_DATE}
            endDate={endDate}
            endDateId={END_DATE}
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              const now = moment();
              if (endDate && endDate.isAfter(now)) {
                setEndDate(now);
              } else {
                setEndDate(endDate);
              }
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            isOutsideRange={(day) => isOutsideRange(day)}
            displayFormat={() => "DD/MM/YYYY"}
            startDatePlaceholderText="Data Inicial"
            endDatePlaceholderText="Data Final"
          />

          {/* <InputLabel>{t("historic_data:filterPanelFactory")}</InputLabel>
            <Select
            // autoWidth
              sx={{
                
                width: "100%",
                height: "60px",
                
              
                position: "relative",
                paddingLeft: "15px",
                fontSize: "18px",
                fontFamily: "Roboto",
                fontWeight: 300,
              }}
              
          
            >
              
              <MenuItem value="RecifePE">Recife (PE)</MenuItem>
            </Select> */}

          <InputLabel>{`Gêmeo :`}</InputLabel>
          {isDigitalTwinDefined && (
            <Select
              sx={{
                width: "100%",
                height: "60px",

                position: "relative",
                paddingLeft: "15px",
                fontSize: "18px",
                fontFamily: "Roboto",
                fontWeight: 300,
              }}
              defaultValue={digitalTwinId}
              onChange={async (e) => {
                const intIndex = parseInt(e.target.value);
                if (!isNaN(intIndex)) {
                  // setSensors([]);
                  setSelectedDigitalTwinId(intIndex);
                  // setSelectedSensors([]);
                  // setSelectedUnits([]);
                } else {
                  console.error("ID do gêmeo digital inválido.");
                }
              }}
            >
              {digitalTwins &&
                digitalTwins.map((e) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
            </Select>
          )}

          <InputLabel sx={{}}>Sensores:</InputLabel>
          <Select
            sx={{
              width: "100%",

              fontSize: "18px",
              fontFamily: "Roboto",
              fontWeight: 300,
            }}
            multiple
            value={selectedSensors.map((sensor) => sensor.id)}
            onChange={(event) => {
              const value = event.target.value;
              const selected = sensors.filter((sensor) =>
                value.includes(sensor.id)
              );
              setSelectedSensors(selected);
            }}
            renderValue={(selected) => (
              <div>
                {selected.map((id) => {
                  const sensor = sensors.find((s) => s.id === id);
                  return sensor ? (
                    <div key={sensor.id}>
                      {sensor.name} ({sensor.unit.abbreviation})
                    </div>
                  ) : null;
                })}
              </div>
            )}
          >
            {sensors &&
              sensors.map((sensor) => {
                const isChecked = selectedSensors.some(
                  (selected) => selected.id === sensor.id
                );
                return (
                  <MenuItem key={sensor.id} value={sensor.id}>
                    <Checkbox
                      checkedIcon={checkedIcon}
                      style={{
                        marginRight: 8,
                        color:
                          theme.palette.mode === "dark" ? "white" : "#323238",
                      }}
                      checked={isChecked} // Verifica se o sensor está selecionado
                    />
                    {`${sensor.name} (${sensor.unit.abbreviation})`}
                  </MenuItem>
                );
              })}
          </Select>
        </Box>

        <Box sx={{ display: "flex", gap: "10px", marginTop: "10px" }}>
          <button
            type="button"
            disabled={selectedSensors.length === 0}
            onClick={
              selectedSensors.length > 0 ? reloadDataOnFormSubmit : () => {}
            }
            style={{
              padding: "10px",
              borderRadius: "6px",
              flexGrow: "1",

              backgroundColor: "#124251",
              color: "white",
              cursor: `${
                selectedSensors.length === 0 ? "not-allowed" : "pointer"
              }`,
            }}
          >
            Aplicar
          </button>

          {selectedSensors.length > 0 && dataToDownload.length > 0 && (
            <button
              style={{
                padding: "10px",
                borderRadius: "6px",
                flexGrow: "1",

                backgroundColor: "#124251",
                color: "white",

                cursor: `${
                  selectedSensors.length === 0 ? "not-allowed" : "pointer"
                }`,
              }}
              onClick={
                dataToDownload.length === 0
                  ? () => {}
                  : () => {
                      downloadCsvHelper(dataToDownload);
                    }
              }
              disabled={
                dataToDownload.length === 0 && selectedSensors.length === 0
              } // Desabilita o botão se nenhum sensor estiver selecionado
            >
              Baixar CSV
            </button>
          )}
        </Box>
      </Paper>

      <Paper
        elevation={0}
        sx={{
          padding: "10px",

          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "18px",
        }}
      >
        {showChart[0] === false &&
          showChart[1] === false &&
          showChart[2] === false && (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <span>Consulte as opções ao lado para visualização</span>
            </div>
          )}

        {showChart[0] === true && (
          <div
            ref={chartContainerRef1}
            onDoubleClick={() => toggleFullscreen(chartContainerRef1)}
            style={{
              height: `calc((100vh - 170px)/${days})`,
              width: "calc(100vw - 490px)",
              backgroundColor:
                theme.palette.mode === "dark" ? "#121212" : "#fff",
              display: "flex",
              justifyContent: "end",
              alignItems: "start",
            }}
          >
            <ReactEcharts
              theme={theme.palette.mode === "dark" ? "dark" : "light"}
              ref={chart1Ref}
              style={{ height: "100%", width: "100%" }}
              option={{}}
            />
            <Fullscreen
              style={{
                cursor: "pointer",
                zIndex: 1,
                paddingTop: "1px",
                position: "absolute",
              }}
              onClick={() => toggleFullscreen(chartContainerRef1)}
            />
          </div>
        )}
        {showChart[1] && (
          <div
            ref={chartContainerRef2}
            onDoubleClick={() => toggleFullscreen(chartContainerRef2)}
            style={{
              height: `calc((100vh - 170px)/${days})`,
              width: "calc(100vw - 490px)",
              backgroundColor:
                theme.palette.mode === "dark" ? "#121212" : "#fff",
              display: "flex",
              justifyContent: "end",
              alignItems: "start",
            }}
          >
            <ReactEcharts
              theme={theme.palette.mode === "dark" ? "dark" : "light"}
              ref={chart2Ref}
              style={{ height: "100%", width: "100%" }}
              option={{}}
            />
            <Fullscreen
              style={{
                cursor: "pointer",
                zIndex: 1,
                paddingTop: "1px",
                position: "absolute",
              }}
              onClick={() => toggleFullscreen(chartContainerRef2)}
            />
          </div>
        )}
        {showChart[2] === true && (
          <div
            ref={chartContainerRef3}
            onDoubleClick={() => toggleFullscreen(chartContainerRef3)}
            style={{
              height: `calc((100vh - 170px)/${days})`,
              width: "calc(100vw - 490px)",
              backgroundColor:
                theme.palette.mode === "dark" ? "#121212" : "#fff",
              display: "flex",
              justifyContent: "end",
              alignItems: "start",
            }}
          >
            <ReactEcharts
              theme={theme.palette.mode === "dark" ? "dark" : "light"}
              ref={chart3Ref}
              style={{ height: "100%", width: "100%" }}
              option={{}}
            />
            <Fullscreen
              style={{
                cursor: "pointer",
                zIndex: 1,
                paddingTop: "1px",
                position: "absolute",
              }}
              onClick={() => toggleFullscreen(chartContainerRef3)}
            />
          </div>
        )}
      </Paper>
    </Box>
  );
};
export default HistoricDetailsView;
