import { useCallback, useEffect, useState,createRef ,useMemo,} from "react";
import { useLocation } from "react-router-dom";
import { Box, CircularProgress, Grid, List, ListItem, Paper, useTheme, Chip, Table, TableBody, TableRow, TableCell, Typography, TableHead } from "@mui/material";


import moment from "moment";
import { useSelector } from "react-redux";
import { getUserId, getUserName,getIsAdmin } from "../../store/slices/userSlice";
import { getDigitalTwinId, getIsDigitalTwinDefined,getDigitalTwin} from "../../store/slices/digitalTwinSlice";
import { getCompanyId } from "../../store/slices/companyslice";
import { getIsDarkTheme } from "../../store/slices/themeSlice";
import ReactEcharts from "echarts-for-react";
import axios from "axios";

import "./DateRangePickerStyles.css";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "react-widgets/styles.css";
import "react-dates/initialize";

import AlertCard from "../../Components/Cards/AlertCard/AlertCard";
import LoadingButton from "../../Components/LoadingButton/LoadingButton";
import AlertsAlarmsService from "../../Services/AlertsAlarmsService";
import NewDi2winDataService from "../../Services/NewDi2winDataService";
import DataService from "../../Services/DataService";

import prescriptionsByTwinId from "../../Utils/prescriptionsByTwinId";
import { sensorName } from "../../Utils/formatString";
import { showLoading } from "../../Utils/showLoading";
import { hideLoading } from "../../Utils/hideLoading";
import { ReactComponent as CienteOk } from "../../Assets/Icons/cienteOk.svg";
import { ReactComponent as Justify } from "../../Assets/Icons/justify.svg";
import { getDb } from "../../store/slices/dbSlice";
import { red } from "@mui/material/colors";
import { add } from "date-fns";

interface Prescricao {
  recommendedValue: any;
  valor_recomendado: number;
  valor_atual: number;
}

const recomendationGraphOptions = (
  type: string,
  data: Array<any>,
  name: string,
  unit: string,
  startTime: any,
  endTime: any,
  startArea: any,
  endArea: any,
  theme: any
) => {


 

  const COLORS_DARK = [ "#F0E442","#7852E0", "#D55E00", "#009E73", "#fff"];
  
  
  const COLORS =  [
    "rgb(18, 66, 81)",
    "#98CBFF",
    "#548BBB",
    "#009E73",
    "#000000",
    "#F0E442",
    "#0072B2",
    "#D55E00",
    "#CC79A7",
  ] 

  return {
    backgroundColor: "transparent",
    title: {
      text: name,
      textStyle: {
       
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 400,
      },
    },
    toolbox: {
      show: true,
      orient: "horizontal",
      // bottom: 0,
      // left: 0,
      right: 10,
      top: -5,
      feature: {
        dataZoom: {
          title: {
            zoom: "Zoom",
            back: "Voltar",

          },
          
          yAxisIndex: "all",
          brushStyle: {
            borderWidth: 1,
            color: 'rgba(255, 173, 17, 0.4)',
            borderColor: 'rgba(255, 173, 17, 1)',
          }


        },
        // restore: {
        //   title: "Reset",

        // },
        saveAsImage: {
          pixelRatio: 4,
          
          title: "Baixar .png",
          // backgroundColor: "rgb(18, 66, 81)",
          // excludeComponents: ["dataZoom", "brush", "magicType", "dataView"],
          name: "Dados Históricos",

        },
        // dataView: {
        //   title: "Ver Dados",
        //   readOnly: true,
          
         

        // },
        magicType: {
          title: {
            line: "Linha",
            bar: "Barra",
          },
          type: ["line", "bar"],
           
        },
        // brush: {
          
        //   type: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
        //   title: {
        //     rect: "Selecão retangular", 
        //     polygon: "Selecão poligonal",
        //     lineX: "Selecão horizontal",
        //     lineY: "Selecão vertical",
        //     keep: "Selecão  continua",
        //     clear: "Limpar",
        //   }
          
        // },
        // restore: {
        //   title: "Resetar",
        // }
        
      }
    },
    brush: {
      xAxisIndex: "all",
      brushLink: "all",
      outOfBrush: {
        colorAlpha: 0.1

      },
      brushType:"rect",

      brushStyle: {
        borderWidth: 1,
    color: 'rgba(255, 173, 177, 0.4)',
    borderColor: 'rgba(255, 173, 177, 1)',

   
      },
      // throttleType: "debounce",
      removeOnClick: true,
      inBrush: {
        color: "red",
        
        // symbol: "rect",
        // symbolSize: 10,
        // showSymbol: true,
      }

  },

    xAxis: {
      type: "time",
      min: startTime,
      max: endTime,
    },
    yAxis: [
      {
        name: unit,
        type: "value",
        alignTicks: true,
        scale: true,
        // min: 0,
        // max:10,
        axisTick: {
          show: true,
          alignWithLabel: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#979797",
          },
        },
        axisLabel: {
          formatter: "{value}",
          color: theme ? "#fff" : "#000",
         
        },
      },
    ],
    dataZoom: [
      {
        type: "inside",

      },
    ],
    tooltip: {
      trigger: "axis",
      // showDelay: 0,
      hideDelay: 5, 
      transitionDuration: 2,
      confine: true,
      axisPointer: {
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      orient: "horizontal",
      bottom: 0,
      itemWidth: 10,
      itemHeight: 10,
    },
    grid: {
        left: "25",
        right: "20",
        bottom: "20",
        top: "60",
        containLabel: true,
    },
    series: [
      {
        data: data,
        yAxisIndex: 0,
        showSymbol: false,
        symbolSize: 5,
        symbol: "circle",
        name: name,
        type: "line",
        
        color: theme ? COLORS_DARK[0] : COLORS[0],
        smooth: false,
        markArea: {
          data: [
            [
              {
                xAxis: startArea,
              },
              {
                xAxis: endArea,
              },
            ],
          ],
          silent: false,
          itemStyle: { 
            color: type === "alert" ? "rgba(255, 255, 160, 0.4)": "rgba(255, 173, 177, 0.4)"
          },
          animation: true,
          animationDuration: 1500,
          animationEasing:" linear",
          animationDelay: 500,
          animationDurationUpdate: 1000,
        },
        animation: true,
        animationDuration: 5000,
        animationEasing:" linear",
        animationDelay: 500,
        animationDurationUpdate: 1000,
      },
    ],
  };
};


const RecomendationDetailsBoard = ({
  selected,
  id,
  kpi,
  message,
  type,
  firstValue,
  startTime,
  registeredUser,
  registeredTime,
  socketAddresses,
  ack,
  PrescriptionSensors,
  recomendation,
}: any) => {

  const isAdmin = useSelector(getIsAdmin);
  const digitalTwin = useSelector(getDigitalTwin);

  const [icon, setIcon] = useState(<></>);
  const [justify, setJustify] = useState(null);
  const [loadingAck, setLoadingAck] = useState(false);
  let recomendationTreated = useMemo<any>(() => [], []);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scenarioDataList, setScenarioDataList] = useState<Array<any>>([]);
  const theme = useTheme();

  const messageObj = JSON.parse(message);

  // Verificar se a chave 'cegn' existe
  if (messageObj.cegn) {
    // Atribuir o valor de 'cegn' à nova chave '3'
    messageObj['5'] = messageObj.cegn;

    // Remover a chave 'cegn'
    delete messageObj.cegn;
  }

  const db = useSelector(getDb);

  useEffect(() => {
    let isComponentMounted = true; // Flag to track mounting status
  
    setScenarioDataList([]); 
  
    const fetchData = async () => {
      if (!isComponentMounted) return; 
  
      if (startTime) {
        const sensors = await DataService.getSensorsWithAlarmDetails(digitalTwin.id);
  
        if (!isComponentMounted) return; 
  
        const promises = sensors.map(async (variable) => {
          const scenarioData = await NewDi2winDataService.getLastNInfoInRange(
            db.dbName,
            db.collection,
            variable.number,
            variable.device_id,
            moment(startTime).add(-10, "minute"),
            moment(startTime),
            "1"
          );
  
          let lastData = scenarioData && scenarioData.pop();
          return {
            ...lastData,
            name: variable.name,
            currentValue: messageObj[lastData?.sensorId] !== undefined 
              ? messageObj[lastData.sensorId].valor_atual.toFixed(2) 
              : "N/A",
            deviceId: variable.device_id,
            recomendedValue: messageObj[lastData?.sensorId] !== undefined 
              ? messageObj[lastData.sensorId].valor_recomendado.toFixed(2) 
              : "N/A",
            unit: variable.type.name === "booleano" ? "" : variable.unit.abbreviation,
          };
        });
  
        const fetchedDataList = await Promise.all(promises);
  
        const filteredDataList = fetchedDataList.filter(
          (data) => data.currentValue !== "N/A" && data.recomendedValue !== "N/A"
        );
    
        if (isComponentMounted) {
          setScenarioDataList(filteredDataList);
        }
      }
    };
  
    fetchData();
  
    // Cleanup function
    return () => {
      isComponentMounted = false;
    };
  }, [startTime, digitalTwin, db]);

  if (type === "alarme") {
    const jsonRecommendation = JSON.parse(message);
    recomendationTreated = Object.entries(jsonRecommendation).map(
      (element: any) => {
        const aux = socketAddresses.find((e) => e.name === element[0]);
        if (aux !== undefined) {
          return {
            name: element[1].name,
            id: aux.metadata.sensorId,
            deviceId: aux.metadata.deviceId,
            readValue: element[1]["Valor atual"],
            recomendedValue: element[1]["Valor recomendado"],
            unit: element[1].unit,
          };
        } else {
          return {};
        }
      }
    );
  }
  if (type === "alerta") {
    const jsonRecommendation = recomendation;


    recomendationTreated = Object.entries(jsonRecommendation).map(
      (element: any) => {
        const aux = Object.keys(element[1]).find((e) => e === element[1]);
        if (aux !== undefined) {
          return {
            name: element[1].name,
            readValue: element[1]["Valor atual"],
            recomendedValue: element[1]["Valor recomendado"],
            unit: element[1].unit,
          };
        } else {
          return {};
        }
      }
    );
//  eslint-disable-next-line @typescript-eslint/no-unused-vars
    recomendationTreated =
      jsonRecommendation &&
      Object.entries(jsonRecommendation).map((element: any) => {
        const aux = socketAddresses.find((e) => e.name === element[0]);
        if (aux !== undefined) {
          return {
            name: element[0],
            id: element.sensorId,
            unit: element.unit,
            deviceId: element.deviceId,
            readValue: element[1][0].value,
            recomendedValue: element[1][4].value,
          };
        } else {
          return {};
        }
      });
  }

  const period = `${new Date(startTime).toLocaleDateString()}, ${new Date(
    startTime
  ).toLocaleTimeString()} `;

  let registered = "Não registrado";
  if (registeredUser && registeredTime) {
    registered = `${registeredUser}, ${new Date(
      registeredTime
    ).toLocaleDateString()}, ${new Date(registeredTime).toLocaleTimeString()}`;
  }



  useEffect(() => {
    selected.selected.justification !== null
      ? setJustify(selected.selected.justification)
      : setJustify(null);
  }, [selected.selected.justification]);
  useEffect(() => {
    setIcon(
      (selected.selected.acknowledged = true ? (
        justify === null ? (
          <CienteOk
            style={{ marginLeft: "10px", position: "relative", top: "3px" }}
          />
        ) : (
          <Justify
            style={{ marginLeft: "10px", position: "relative", top: "3px" }}
          />
        )
      ) : (
        <></>
      ))
    );
  }, [selected, justify]);

  return (
    <Paper
      elevation={0}
      sx={{
        
       
       
        
        alignItems: "start",
        
        listStyle: "none",
        
        height: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        padding: "10px",
        gap: "10px",
       
      }}
    >
      
       
          <Box
            
          >
            <Box
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                
                fontSize: "18px",
                
              }}
            >
              {type === "alarme" ? "Alarme" : type === "alerta" ? "Alerta" : ""}
            </Box>

            <List
              sx={{

                alignItems: "start",

                height: "150px",
                overflow: "auto",
                
                
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "14px",
                
              }}
            >
              <ListItem
                
              >
                Descrição: {`${kpi.name} fora do intervalor desejável `} {`(${parseFloat(
                  type === "alarme"
                    ? firstValue
                    : type === "alerta"
                    ? selected.recomendation["eficiência atual"].toFixed(2)
                    : null
                ).toFixed(2)} ${kpi.unit})`}
              </ListItem>

              <ListItem
               
              >
                Início: {period}
              </ListItem>

              <ListItem
                
              >
                Ciente: {registered} {registered !== "Não registrado" && icon}
              </ListItem>
              {justify && (
                <ListItem
                  sx={{
                    width: "100%",
                    lineHeight: "25px",
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "16px",
                  }}
                >
                 Justificativa: {justify}
                </ListItem>
              )}

              {registered === "Não registrado" && !isAdmin && (
                <LoadingButton
                  text={"Registrar Ciência"}
                  loading={loadingAck}
                  onClick={() => {
                    setLoadingAck(true);
                    ack(id);
                    setTimeout(() => {
                      setLoadingAck(false);
                    }, 2000);
                  }}
                />
              )}

            </List>
          </Box>

          <Box
           sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
           }}
          >
            <Box
               sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                
                fontSize: "18px",
               }}
            >
              Prescrição:
            </Box>

          <List
            sx={{
              height: "150px",
              overflow: "auto",
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "14px",
            }}
          >
            {messageObj && scenarioDataList.length > 0 &&
              Object.entries(scenarioDataList)
                .sort(([, a], [, b]) => a.sensorId - b.sensorId)
                .map(([key, data]) => {
                  return (
                    <ListItem key={key} sx={{ width: "100%" }}>
                      {data.name}:
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <Chip
                          label={`Atual: ${data.currentValue} ${data.unit || ""}`}
                        />
                        <Chip
                          label={`Recomendado: ${data.recomendedValue} ${data.unit || ""}`}
                        />
                      </Box>
                    </ListItem>
                  );
                })}

            {scenarioDataList.length === 0 && 
              <ListItem sx={{ width: "100%" }}>
                <CircularProgress size={20} color="inherit" /> 
              </ListItem>
            }
          </List>
                
           
          </Box>

          <Box
            
          >
            <Box
               sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                
                fontSize: "18px",
               }}
            >
              Cenário:
            </Box>

           
              <List
               sx={{
                
                height: "150px",
                overflow: "auto",
                
                
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "14px",
                
              }}
              >
                {/* {console.log(scenarioDataList)} */}
                {scenarioDataList
                  .sort((a, b) => a.sensorId - b.sensorId)
                  .map((scenarioData, index) => {
                    
                    const label = typeof scenarioData.value === "number" 
                      ? scenarioData.value.toFixed(2) + " " + scenarioData.unit 
                      : scenarioData.value;

                    return (
                      <ListItem key={index} sx={{ width: "100%" }}>
                        {scenarioData.name}: <Chip label={label} />
                      </ListItem>
                    );
                  })}

                {scenarioDataList.length === 0 && 
                <ListItem sx={{ width: "100%" }}>
                  
                  <CircularProgress size={20} color="inherit" /> 
                  </ListItem>
                }
                
              </List>
            
          </Box>
    </Paper>
  );
};



const AssociatedGraphsBoard = ({
  selected,
  kpi,
  type,
  startTime,
  endTime,
  registeredSensorData,
  finished,
}) => {
  const graphData = registeredSensorData.map((sensor) => {
    sensor.ref = createRef<any>();
    return sensor;
  });
  const theme = useTheme();
  const db = useSelector(getDb);

  useEffect(() => {

    const graphStart = new Date(startTime)

    graphStart.setMinutes(graphStart.getMinutes() - 1);
    // graphStart.setHours(graphStart.getHours() - 5);

      
      
    const graphEnd = new Date(endTime)
    !selected.finish && graphEnd.setMinutes(graphEnd.getMinutes() + 1)
    // graphEnd.setHours(graphEnd.getHours() - 5);

      
     

    showLoading(graphData.map((e) => e.ref));
    for (const graph of graphData) {
      db && NewDi2winDataService.getRawInfoForAlert(
        db.dbName,
        db.collection,
        graph.id,
        graph.deviceId,
        moment(graphStart).add(-0, "hours"),
        moment(graphEnd).add(-0, "hours")
      ).then((rawData) => {
        if (rawData && rawData.name !== "Error") {
          const data = rawData.map((element) => {
            // console.log(element.timestamp);
            const timestamp = moment(element.timestamp).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
         
            if (typeof element.value === "string") {
              return [timestamp, parseFloat(element.value).toFixed(2)];
            }
            if (typeof element.value === "number") {
              return [timestamp, element.value.toFixed(2)];
            }
            return [timestamp, element.value];
          });

          if (graph.id === "1025" && graph.deviceId === "925XX") {
            graph.ref.current
              ?.getEchartsInstance()
              .setOption(
                recomendationGraphOptions(
                  type,
                  data,
                  graph.name,
                  graph.unit,
                  graphStart.toISOString(),
                  graphEnd.toISOString(),
                  moment(startTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  moment(endTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  theme.palette.mode === "dark" ? true : false
                )
              );
          } else {
            // console.log(moment(startTime).toISOString());
            // console.log(graphStart)
            // console.log(moment(endTime).toISOString());
            // console.log(graphEnd)
            // console.log(data);
            graph.ref.current
              ?.getEchartsInstance()
              .setOption(
                recomendationGraphOptions(
                  type,
                  data,
                  sensorName(graph.name !== undefined ? graph.name : " ") + "",
                  graph.unit,
                  graphStart.toISOString(),
                  graphEnd.toISOString(),
                  moment(startTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  moment(endTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  theme.palette.mode === "dark" ? true : false
                )
              );
          }
          hideLoading([graph.ref]);
        }
      });
    }
    
  }, [selected]);

  const [started, setStarted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStarted(true);
    }, Math.random() * 500);
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start",justifyContent: "start"}}>

      <Box
        sx={{
          opacity: started ? 1 : 0,
          transition: "opacity 0.5s ease-in-out",
         
          fontFamily: "Roboto",
          fontWeight: 400,
          
          fontSize: "18px",
         
        }}
      >
        {kpi ? "Impacto do" : "Associados ao"} {type === "alert" ? "Alerta" : "Alarme"}
      </Box>


      <List sx={{
        width: "100%",
        height: "calc(100vh - 410px)",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        
       }}>

        {graphData.map((chart: any, i: number) => {
          return  <ListItem key={i} sx={{}}>
              <ReactEcharts
                ref={chart.ref}
                theme={theme.palette.mode === "dark" ? "dark" : "light"}
                style={{  height: "260px", width: "100%"}}
                option={
                  recomendationGraphOptions(
                    type,
                    [],
                    sensorName(chart.name !== undefined ? chart.name : " ") + "",
                    chart.unit,
                    moment(startTime).toISOString(),
                    moment(endTime).toISOString(),
                    moment(startTime).toISOString(),
                    moment(endTime).toISOString(),
                    theme.palette.mode === "dark" ? true : false
                  )
                }
                
              />
            </ListItem>
          
        })}

      </List>

    </Box>
  );
};




const RecommendationHistoryView = () => {
  const companyId = useSelector(getCompanyId);
  const isDigitalTwinDefined = useSelector(getIsDigitalTwinDefined);
  const digitalTwinId = useSelector(getDigitalTwinId);
  const isDarkTheme = useSelector(getIsDarkTheme);
  const userId = useSelector(getUserId);
  const userName = useSelector(getUserName);

  const [loading, setLoading] = useState<boolean>(true);
  const [settings, setSettings] = useState<any>(null);
  const [data, setData] = useState<any>([]);
  const [selected, setSelected] = useState<any>();
  const [di2winSensorData, setDi2winSensorData] = useState<any>(null);

  // const [startDate, setStartDate] = useState<Moment>(
  //   moment().subtract(7, "days")
  // );
  // const [endDate, setEndDate] = useState<Moment>(moment(now()));
  // const [focusedInput, setFocusedInput] = useState();
  // const [days, setDays] = useState(7);
  const location = useLocation();
  const passedState = location.state;
  const [passedAlertId, setPassedAlertId] = useState(
    passedState ? passedState.alertId : null
  );

  const ackAlarm = async (alarmId) => {
    if (userId) {
      AlertsAlarmsService.ackAlarm(userId, alarmId).then((response) => {
        const i = data.findIndex(
          (e: any) => e.id === alarmId && e.type === "alarm"
        );
        const newData: any = [...data];
        newData[i].user = { name: userName };
        newData[i].user_id = userId;
        newData[i].acknowledged = true;
        newData[i].acknowledged_time = moment().toISOString();
        setData(newData);
        const newSelected: any = { ...selected };
        newSelected.registeredUser = userName;
        newSelected.registeredTime = moment().toISOString();
        setSelected(newSelected);
        
      });
    }
  };

  const ackAlert = async (alertId) => {
    if (userId) {
      AlertsAlarmsService.ackAlert(userId, alertId).then((response) => {
        const i = data.findIndex(
          (e: any) => e.id === alertId && e.type === "alert"
        );
        const newData: any = [...data];
        newData[i].user = { name: userName };
        newData[i].user_id = userId;
        newData[i].acknowledged = true;
        newData[i].acknowledged_time = moment().toISOString();
        setData(newData);
        const newSelected: any = { ...selected };
        newSelected.registeredUser = userName;
        newSelected.registeredTime = moment().toISOString();
        setSelected(newSelected);
      });
    }
  };

  const fetchAlarmsData = useCallback(async () => {
    if (settings && isDigitalTwinDefined) {
      const dataFetched: any = [];
      for (const twin of settings.digitalTwins) {
        const rawAlarms = await AlertsAlarmsService.getAlarmsRange(
          twin.id,
          moment().subtract(30, "days"),
          moment()
        );

        const formatedAlarms = selected
          ? rawAlarms.map((alarm) =>
              alarm.id !== selected.id
                ? {
                    ...alarm,
                    selected: false,
                    companyName: settings.name,
                    dtName: twin.name,
                    type: "alarm",
                    associatedSensors: twin.sensors,
                  }
                : {
                    ...alarm,
                    selected: true,
                    companyName: settings.name,
                    dtName: twin.name,
                    type: "alarm",
                    associatedSensors: twin.sensors,
                  }
            )
          : rawAlarms.map((alarm) => {
              return {
                ...alarm,
                companyName: settings.name,
                dtName: twin.name,
                type: "alarm",
                associatedSensors: twin.sensors,
                selected: false,
              };
            });
        dataFetched.push(...formatedAlarms);
      }
      return dataFetched;
    }
  }, [settings, isDigitalTwinDefined, selected]);

  

  const aggregate = useCallback(async () => {
    const aux1 = (await fetchAlarmsData()) || [];
    if (aux1.length === 0) {
      setLoading(false);
      return;
    }

    //Provável principal motivo da lentidão no carregamento dos alarmes
    const ordenated = aux1.sort((a, b) => {
      if (moment(a.first_alert_time).isBefore(moment(b.first_alert_time)))
        return 1;
      if (moment(a.first_alert_time).isAfter(moment(b.first_alert_time)))
        return -1;
      return 0;
    });
    setData(ordenated);
    setLoading(false);

    //settando alarme que foi clicado na tela de monitoramento
    if (passedAlertId) {
      const passedAlertIndex = ordenated.findIndex(
        (element) => element.id === passedAlertId
      );
      const passedAlert = ordenated[passedAlertIndex];
      ordenated[passedAlertIndex].selected = true;

      setSelected({
        selected: passedAlert,
        predictions: passedAlert.predictions
          ? passedAlert.predictions
          : undefined,
        associatedSensors: passedAlert.associatedSensors,
        address: passedAlert.address,
        recomendation: passedAlert.recomendation,
        i: passedAlertIndex,
        id: passedAlert.id,
        type: passedAlert.type,
        message: passedAlert.message,
        startTime: passedAlert.first_alert_time,
        endTime: passedAlert.last_alert_time,
        firstValue: passedAlert.first_value,
        lastValue: passedAlert.last_value,
        finished: passedAlert.finished,
        registeredUser: passedAlert.user?.name,
        registeredTime: passedAlert.acknowledged_time,
        kpi: [
          {
            name: passedAlert.sensor.name,
            deviceId: passedAlert.sensor.device_id,
            id: passedAlert.sensor.number,
            unit: passedAlert.sensor.unit.abbreviation,
          },
        ],
      });
      setPassedAlertId(null);
    }
  }, [fetchAlarmsData, passedAlertId]);

  const updateDataOnSelect = async (i) => {
    const oldI = data.findIndex((e: any) => e.selected === true);
    const newData: any = [...data];
    newData[i].selected = true;
    if (oldI !== -1) {
      newData[oldI].selected = false;
    }
    setData(newData);
  };

  useEffect(() => {
    if (isDigitalTwinDefined) {
      const promise1 = axios.get(`/companies/hist/${companyId}`);

      //Busca metadados da IA necessarios pela
      //para renderizar as recomendacaoes
      const promise2 = axios.get(
        `/newApi/getSensorsInfo?digitalTwinId=${digitalTwinId}`
      );

      //
      Promise.all([promise1, promise2]).then((responses) => {
        setDi2winSensorData(responses[1].data);
        const aux = {
          name: responses[0].data.name,
          digitalTwins: responses[0].data.digitalTwins.map((digitalTwin) => {
            return {
              id: digitalTwin.id,
              name: digitalTwin.name,
              sensors: digitalTwin.sensors
                .map((sensor, index) => {
                  if (sensor.alert_detail) {
                    return {
                      indexAux: index,
                      name: sensor.name,
                      status: false,
                      id: sensor.number,
                      deviceId: sensor.device_id,
                      unit: sensor.unit.abbreviation,
                      precedence: sensor.precedence,
                    };
                  } else return null;
                })
                .filter((element) => {
                  return element !== null;
                }),
            };
          }),
        };
        setSettings(aux);
      });
    }
    return () => {};
  }, [companyId, digitalTwinId, isDigitalTwinDefined]);

  // useEffect(() => {
  //   setDays(moment(now()).diff(moment(startDate), "days"));
  // }, [startDate]);

  useEffect(() => {
    if (settings !== null) {
      aggregate();
    }
    const interval = setInterval(() => {
      if (settings !== null) {
        aggregate();
      }
    }, 180000);
    return () => {
      clearInterval(interval);
    };
  }, [aggregate, settings]);

  useEffect(() => {
    data.length === 0 && setLoading(false);
  }, [data]);



  const [started, setStarted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStarted(true);
    }, Math.random() * 500);
  }, []);

  return !settings ? (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "calc(100vh - 200px)",gap:'10px' }}><CircularProgress color={"inherit"} size={"25px"} /> {`Carregando ... ${'Histórico de Recomendações'}`}</Box>

  ) : (
    <Grid container spacing={1} sx={{ height: "100%" ,width:'calc(100% - 40px)' ,padding:'10px'}}>
      <Grid item xs={3}>
        <Paper
          elevation={0}
          sx={{
            opacity: started ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
            
            
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            
            
          }}
        >
          <Box
            sx={{
          
              fontFamily: "Roboto",
              fontWeight: 400,
              
              fontSize: "18px",
              width: "100%",

            }}
          >
            Histórico de Recomendações
          </Box>


          <List
            sx={{
              width: "100%",
              height: "calc(100vh - 200px)",
              overflow: "auto",
            
            }}
          >
            
        

        

            {loading ?
              
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "calc(100vh - 200px)",gap:'10px' }}><CircularProgress color={"inherit"} size={"25px"} /> {`Carregando...`}</Box>
                
              :

              data.length > 0 &&
              data
                
                .map((element: any, i: number) => {
                  return (
                    <ListItem style={{ width: "100%" }} key={i}>
                      <AlertCard
                        element={element}
                        checked={element.acknowledged}
                        type={element.type}
                        company={element.companyName}
                        digitalTwin={element.dtName}
                        selected={element.selected}
                        onClick={() => {
                          updateDataOnSelect(i);
                          setSelected({
                            selected: element,
                            predictions: element.predictions
                              ? element.predictions
                              : undefined,
                            associatedSensors: element.associatedSensors,
                            address: element.address,
                            recomendation: element.recomendation,
                            i: i,
                            id: element.id,
                            type: element.type,
                            message: element.message,
                            startTime: element.first_alert_time,
                            endTime: element.last_alert_time,
                            firstValue: element.first_value,
                            lastValue: element.last_value,
                            finished: element.finished,
                            registeredUser: element.user?.name,
                            registeredTime: element.acknowledged_time,
                            kpi: [
                              {
                                name: element.sensor.name,
                                deviceId: element.sensor.device_id,
                                id: element.sensor.number,
                                unit: element.sensor.unit.abbreviation,
                              },
                            ],
                          });
                          
                        }}
                      />
                    </ListItem>
                  );
                })}
          </List>


        </Paper>
      </Grid>

      <Grid item xs={9}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>

          {selected ? (
            <Grid container spacing={1}>

            <Grid item xs={12}>
                  <RecomendationDetailsBoard
                  selected={selected}
                  id={selected.id}
                  kpi={selected.kpi[0]}
                  message={selected.message}
                  recomendation={selected.recomendation}
                  type={selected.predictions !== undefined ? "alerta" : "alarme"}
                  firstValue={selected.firstValue}
                  lastValue={selected.lastValue}
                  startTime={selected.startTime}
                  endTime={selected.endTime}
                  finished={selected.finished}
                  registeredUser={selected.registeredUser}
                  registeredTime={selected.registeredTime}
                  socketAddresses={di2winSensorData}
                  isDarkTheme={isDarkTheme}
                  ack={selected.type === "alert" ? ackAlert : ackAlarm}
                  PrescriptionSensors={prescriptionsByTwinId}
                />
            </Grid>

            <Grid item xs={6}>
            <Paper
              elevation={0}
              sx={{
                opacity: started ? 1 : 0,
                transition: "opacity 0.5s ease-in-out",
                
                display: "flex-column",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: "10px",
                
                
              }}
            >
              <AssociatedGraphsBoard
                selected={selected}
                kpi={false}
                type={selected.type}
                startTime={selected.startTime}
                endTime={selected.endTime}
                registeredSensorData={selected.associatedSensors.filter(
                  (e) => e.id !== selected.kpi[0].id
                ).sort((a, b) => a.id - b.id)}
                finished={selected.finished}
              />
            </Paper>
            </Grid>

            <Grid item xs={6}>
            <Paper
              elevation={0}
              sx={{
                opacity: started ? 1 : 0,
                transition: "opacity 0.5s ease-in-out",
                
                display: "flex-column",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: "10px",

              }}
            >
              <AssociatedGraphsBoard
                selected={selected}
                kpi={true}
                type={selected.type}
                startTime={selected.startTime}
                endTime={selected.endTime}
                registeredSensorData={selected.kpi}
                finished={selected.finished}
              />
            </Paper>
            </Grid>
            
            </Grid>
          ) : (
            <Paper
              elevation={0}
              sx={{
                display: "flex",
                opacity: started ? 1 : 0,
                transition: "opacity 0.5s ease-in-out",
                
                width: "100%",
                height: "calc(100vh - 155px)",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "18px",
                padding: "10px",
  
              }}
            >
              Selecione uma recomendação
            </Paper>
          )}
          
        </Box>
      </Grid>
    </Grid>
  );
};

export default RecommendationHistoryView;
