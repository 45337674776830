import AuthService from "../Services/AuthService";
import DataService from "../Services/DataService";
import paths from "../router/paths";
import { setCompany } from "../store/slices/companyslice";
import { setDb } from "../store/slices/dbSlice";
import { setDigitalTwinsList } from "../store/slices/digitalTwinsListSlice";
import { setUser } from "../store/slices/userSlice";

const makeLogin = async (email: string, password: string, dispatch) => {
  // Chamando serviço que submete os dados para o servidor fazer login
  const loginResponse = await AuthService.login(email, password);
  // console.log(loginResponse.data)
  const companies = loginResponse.data.user.companies ;
  const twinsResponse =  companies.forEach(async companie => {
    await DataService.getTwinsByCompany(companie.id);
  }); 
  if (companies.length > 0) {
    // console.log(companies )
    const company = companies[0];
    // console.log(company.group.logo)
    dispatch(setCompany(company));
    const twinsResponse = await DataService.getTwinsByCompany(company.id);
    dispatch(setDigitalTwinsList({ companyId: company.id, payload: twinsResponse }));
    dispatch(setDb({ dbName: company.group.db_name, collection: company.group.collection_name }));
   
  } 
    
  
  
  const userData = {
    token: loginResponse.data.token,
    id: loginResponse.data.user.id,
    name: loginResponse.data.user.name,
    email: loginResponse.data.user.email,
    permissionLevel: loginResponse.data.user.permition_level,
    perfil: loginResponse.data.user.perfil,
    companies: loginResponse.data.user.companies,
    twins: loginResponse.data.user.twins,
  };
  
  
  
  dispatch(setUser(userData));
  dispatch(setDigitalTwinsList({ companyId: companies.id, payload: twinsResponse }));
  dispatch(setCompany({ companies }));
  
 
  

  return userData.permissionLevel === "in_forma" ? paths.CHOOSE_COMPANY : paths.PANEL_TWINS;
};

export default makeLogin;